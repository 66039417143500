.group {
}

.standard {
  & :global(> div[data-block="core/paragraph"]:last-of-type) {
    @apply pb-10 md:pb-15 lg:pb-20;
  }

  &:global(:not(.transparent)) {
    @apply pt-8 pb-[1px];
  }

  &:global(.transparent) {
    @apply mb-0;
  }

  &:global(.good-light-green) {
    @apply bg-good-green-10;
  }

  &:global(.good-light-blue) {
    @apply bg-good-blue-5;
  }

  &:global(.white) {
    @apply bg-white;
  }
}

.article {
  @apply bg-transparent !important;

  & :global(> .inner-wrapper) {
    @apply pt-6 rounded;
    & > div:last-of-type {
      @apply pb-[1px];
    }

    & > div:last-of-type {
      @apply pb-8 mb-0;
    }
  }

  &:global(.good-light-green .inner-wrapper) {
    @apply bg-good-green-10;
  }

  &:global(.good-light-blue .inner-wrapper) {
    @apply bg-good-blue-10;
  }

  &:global(.transparent .inner-wrapper) {
    @apply py-0;
  }
}
